import { css, Theme } from '@emotion/react';

export default {
  img: {
    container: (theme: Theme) => css`
      flex: 0 0 50%;
      max-width: 60%;
      display: none;

      ${theme.breakpoints.up('md')} {
        padding-top: ${theme.spacing(2)};
        display: flex;
        justify-content: center;
        position: relative;
      }
    `,
    shapeShadow: css`
      z-index: 1;
      position: absolute;
      top: 0;
      left: 10%;
      width: 60%;

      path {
        fill: #03315c;
      }
    `,

    shapeImg: (theme: Theme) => css`
      z-index: 2;
      margin-bottom: calc(-1 * ${theme.spacing(2)});
      width: 60%;
    `,
  },
  form: {
    input: (theme: Theme) => css`
      position: relative;
      margin-bottom: calc(${theme.spacing(2)} * 2);

      label {
        color: white;
      }

      label.Mui-focused {
        color: white;
      }

      .MuiInput-input {
        width: 100%;
        padding: calc(${theme.spacing(2)} / 2) 0 calc(${theme.spacing(2)} / 2) 0;
        color: white;
      }

      & .MuiInput-root:before,
      &:hover .MuiInput-root:before {
        border-bottom: white solid 1px;
      }

      .MuiInput-underline:after {
        border-bottom-color: white;
      }

      .MuiOutlinedInput-root {
        fieldset {
          border-color: white;
        }

        &:hover fieldset {
          border-color: white;
        }

        .Mui-focused fieldset {
          border-color: white;
        }
      }
    `,
  },
};
