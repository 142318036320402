import { useState } from 'react';

import { css } from '@emotion/react';
import { LoadingButton } from '@mui/lab';
import { TextField, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { Types, useSubscribeNewsletter, useTranslation } from '@scorenco/core';
import { useFormik } from 'formik';
import { string, object } from 'yup';

import { Shape } from '../../illustrations';
import { Wrapper } from '../../primitives';

import style from './style';

const newsletterSchema = object().shape({
  email: string().email().required(),
});

const Shapes = ({ image }: { image?: string }) => {
  const {
    img: { container, shapeShadow, shapeImg },
  } = style;

  return (
    <Box css={container}>
      <Shape css={shapeShadow} />
      <Shape src={image} css={shapeImg} />
    </Box>
  );
};

type NewsletterSliceProps = {
  slice: Types.ComponentSlicesNewsletter;
};

export const NewsletterSlice = ({ slice }: NewsletterSliceProps) => {
  const { t } = useTranslation();
  const [registered, setRegistered] = useState(false);
  const subscribeNewsletterQuery = useSubscribeNewsletter();

  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema: newsletterSchema,
    onSubmit: async (values) => {
      await subscribeNewsletterQuery.execute({
        email: values.email,
      });
      formik.resetForm();
      setRegistered(true);
    },
  });

  return (
    <Wrapper
      my={2}
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
      `}
    >
      <Stack
        maxWidth="md"
        direction="row"
        alignItems="center"
        css={(theme) => css`
          background: linear-gradient(
            to bottom,
            ${theme.vars.palette.primary.main} 0%,
            ${theme.vars.palette.primary.dark} 80%
          );
          color: ${theme.vars.palette.primary.contrastText};
          display: flex;
          border-radius: 0.5rem;
          width: 100%;
        `}
      >
        <Shapes image={slice.image?.data?.attributes?.url} />

        {!registered ? (
          <Box
            component="form"
            flex={1}
            mx={4}
            py={[4, 6]}
            onSubmit={formik.handleSubmit}
          >
            <Typography variant="h2" textAlign="left">
              {slice.title}
            </Typography>
            {!registered && (
              <Box css={style.form.input}>
                <TextField
                  variant="standard"
                  name="email"
                  label="Adresse email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={
                    formik.touched.email &&
                    formik.errors.email &&
                    t(formik.errors.email)
                  }
                />
              </Box>
            )}
            <LoadingButton
              variant="contained"
              color="secondary"
              loading={subscribeNewsletterQuery.status.loading}
              type="submit"
            >
              {registered ? 'Merci' : slice.buttonText}
            </LoadingButton>
          </Box>
        ) : (
          <Box flex={1} mx={4} py={[4, 6]}>
            <Typography variant="h2" textAlign="left">
              {slice.title}
            </Typography>
            <Typography mt={2}>{slice.signedText}</Typography>
          </Box>
        )}
      </Stack>
    </Wrapper>
  );
};
