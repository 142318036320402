import { useCallback } from 'react';

import { useMutation } from '@apollo/client';

import {
  InsertNewslettersContactDocument,
  InsertNewslettersContactMutationVariables,
} from './Newsletter.mutation.generated';

export const useSubscribeNewsletter = () => {
  const [runMutation, status] = useMutation(InsertNewslettersContactDocument, {
    context: {
      role: 'anonymous',
    },
  });

  const execute = useCallback(
    (variables: InsertNewslettersContactMutationVariables) => {
      return runMutation({
        variables,
      });
    },
    [runMutation]
  );

  return { execute, status };
};
